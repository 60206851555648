import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AdminDetailsProvider from './contexts/adminContext';
import AppRoute from './enums/routes';
import Login from './pages/Login';
import PrivateRoutes from './routes/PrivateRoutes';

function App() {
  return (
    <AdminDetailsProvider>
      <Router>
        <Switch>
          <Route path={AppRoute.Login} component={Login} />
          <PrivateRoutes />
        </Switch>
      </Router>
    </AdminDetailsProvider>
  );
}

export default App;
