import { notify } from '@relaypay/uikit';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import Button from '../../../components/Button';
import Input from '../../../components/InputField';
import { UserDetailsContext } from '../../../contexts/userContext';
import { emptyUserDetails, UserDetails } from '../../../interfaces/user';
import useGetUserDetails from '../../../services/api/user/getUserDetails';
import { enterDetailsFormSchema, EnterDetailsFormValuesProps } from './helpers';

const EnterDetails: React.FC = () => {
  const { setUserDetails } = useContext(UserDetailsContext);

  const [searchParams, setSearchParams] = useState<EnterDetailsFormValuesProps>(
    { txHash: '', email: '', txId: '' }
  );

  const {
    handleSubmit,
    handleChange,
    validateField,
    errors,
    values: { txHash, txId, email }
  } = useFormik<EnterDetailsFormValuesProps>({
    initialValues: {
      txHash: '',
      email: '',
      txId: ''
    },
    validationSchema: enterDetailsFormSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      setSearchParams({ ...values });
    }
  });

  const { isLoading } = useGetUserDetails(
    { ...searchParams },
    {
      onSuccess: (data: UserDetails) => {
        setUserDetails(data);
      },
      onError: (err: AxiosError) => {
        notify({ type: 'error', message: err.response?.data.message });
        setUserDetails(emptyUserDetails);
      }
    }
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="xl:w-2/3 bg-white flex flex-col md:flex-row p-4 space-y-6 space-x-0 md:space-x-4">
        <div className="w-full space-y-5">
          <h2 className="text-rp-black">Enter Details</h2>
          <Input
            name="txHash"
            label="Transaction Hash"
            placeholder="Enter transaction hash"
            value={txHash}
            onChange={handleChange}
            onBlur={() => validateField('txHash')}
            error={errors.txHash}
          />
          <Input
            name="email"
            label="Email address"
            placeholder="Enter email address"
            value={email}
            onChange={handleChange}
            onBlur={() => validateField('email')}
            error={errors.email}
          />
          <Input
            name="txId"
            label="Transaction ID"
            placeholder="Enter transaction id"
            value={txId}
            onChange={handleChange}
            onBlur={() => validateField('txId')}
            error={errors.txId}
          />
        </div>
        <div className="flex items-end md:w-48">
          <Button type="submit" primary loading={isLoading}>
            Search
          </Button>
        </div>
      </div>
    </form>
  );
};

export default EnterDetails;
