import React from 'react';
import MainContainer from '../main-container';
import SideBarContainer from '../sidebar';

const AppLayout: React.FC = (props) => {
  return (
    <div className="h-screen md:flex overflow-auto bg-rp-grey">
      <SideBarContainer />
      <MainContainer {...props} />
    </div>
  );
};

export default AppLayout;
