import { notify } from '@relaypay/uikit';
import { AxiosError } from 'axios';
import Button from 'components/Button';
import Input from 'components/InputField';
import { AdminDetailsContext } from 'contexts/adminContext';
import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import useConfirmFraudTx from 'services/api/user/confirmFraudTx';
import fraudTxFormSchema from './helpers';

const FraudTx: React.FC = () => {
  const [emailResponse, setEmailResponse] = useState('');
  const { isAdmin } = useContext(AdminDetailsContext);

  const {
    handleSubmit,
    handleChange,
    validateField,
    values: { monoovaId },
    errors
  } = useFormik({
    initialValues: {
      monoovaId: ''
    },
    validationSchema: fraudTxFormSchema,
    onSubmit: () => refetch()
  });

  const { isLoading, refetch } = useConfirmFraudTx(
    { monoovaId },
    {
      onSuccess: (data: string) => {
        setEmailResponse(data);
        notify({ message: 'Transaction confirmed as fraud.' });
      },
      onError: ({ response }: AxiosError) => {
        notify({ type: 'error', message: response?.data.message });
      }
    }
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="xl:w-2/3 bg-white p-4">
        <div className="flex flex-col space-y-6">
          <div className="w-full space-y-5">
            <h2 className="text-rp-black">Block Fraud Transaction</h2>
            <Input
              name="monoovaId"
              label="Monoova ID"
              placeholder="Enter monoova id"
              value={monoovaId}
              onChange={handleChange}
              onBlur={() => validateField('monoovaId')}
              error={errors.monoovaId}
            />
          </div>
          <div className="flex items-end md:w-48">
            <Button
              type="submit"
              primary
              loading={isLoading}
              disabled={!isAdmin}
            >
              Submit
            </Button>
          </div>
          <div className="">{emailResponse && emailResponse}</div>
        </div>
      </div>
    </form>
  );
};

export default FraudTx;
