import jwtDecode, { JwtPayload } from 'jwt-decode';
import { useContext, useEffect, useState } from 'react';

import { AdminDetailsContext } from '../contexts/adminContext';

const useToken = () => {
  const {
    adminDetails: { firstName }
  } = useContext(AdminDetailsContext);
  const [tokenState, setTokenState] = useState<string | null>();

  useEffect(() => {
    setTokenState(window.localStorage.getItem('token'));
  }, []);

  const setToken = (token: string | null) => {
    if (token) {
      window.localStorage.setItem('token', token);
    } else {
      window.localStorage.removeItem('token');
    }
    setTokenState(token);
  };

  const isAuthenticated = () => {
    if (firstName) {
      return true;
    }
    const token = window.localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode<JwtPayload>(token);
      if (decoded.exp && decoded.exp * 1000 > Date.now()) {
        return true;
      }
    }

    return false;
  };

  return { token: tokenState, setToken, isAuthenticated };
};

export default useToken;
