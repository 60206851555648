import React from 'react';
import Button from '../../Button';

interface PaginationProps {
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageOptions: number[];
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  nextPage: () => void;
  previousPage: () => void;
  setPageSize: (pageSize: number) => void;
}

const TablePagination: React.FC<PaginationProps> = ({
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  pageIndex,
  pageSize,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize
}) => {
  return (
    <div className="text-right space-x-6">
      <div className="inline-flex space-x-2">
        <Button
          primary
          size="small"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          First
        </Button>
        <Button
          primary
          size="small"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          Previous
        </Button>
      </div>
      <span>
        Go to page:
        <input
          type="number"
          className="focus:outline-none border border-gray-300 w-16 px-1 ml-1"
          defaultValue={pageIndex + 1}
          onChange={(e) => {
            const pageNum = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(pageNum);
          }}
        />
      </span>
      <span>
        Page{' '}
        <strong>
          {pageIndex + 1} of {pageOptions.length}
        </strong>{' '}
      </span>
      <select
        value={pageSize}
        className="border"
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
      >
        {[5, 10, 20, 30].map((pgSize) => (
          <option key={pgSize} value={pgSize}>
            {' '}
            Show {pgSize}
          </option>
        ))}
      </select>
      <div className="inline-flex space-x-2">
        <Button
          primary
          size="small"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          Next
        </Button>
        <Button
          primary
          size="small"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          Last
        </Button>
      </div>
    </div>
  );
};

export default TablePagination;
