import axios from 'axios';
import { useQuery } from 'react-query';

const verifyMerchant = async (merchantId: string) => {
  const { data } = await axios.patch(
    `${process.env.REACT_APP_API_URL}/office/merchant/verify`,
    {
      headers: {
        params: { merchantId, isVerified: true },
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }
  );
  return data;
};

const useVerifyMerchant = (merchantId: string, props = {}) =>
  useQuery(
    ['verifyMerchant', { merchantId }],
    () => verifyMerchant(merchantId),
    {
      enabled: false,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...props
    }
  );

export default useVerifyMerchant;
