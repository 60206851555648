import axios from 'axios';
import { useQuery } from 'react-query';

export const getAdminDetails = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/private/user`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }
  );
  return data;
};

const useGetAdminDetails = (props = {}) =>
  useQuery(['getAdminDetails'], () => getAdminDetails(), {
    enabled: false,
    retry: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    ...props
  });

export default useGetAdminDetails;
