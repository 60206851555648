import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { AxiosError } from 'axios';
import { useIsFetching } from 'react-query';
import { notify } from '@relaypay/uikit';
import Button from '../../components/Button';
import Input from '../../components/InputField';
import { loginFormSchema, LoginFormValuesProps, Token } from './helpers';
import useLogin from '../../services/api/admin/login';
import useToken from '../../hooks/useToken';
import useGetAdminDetails from '../../services/api/admin/getAdminDetails';
import { AdminDetailsContext } from '../../contexts/adminContext';
import { AdminDetails, AdminRole, allowedRoles } from '../../interfaces/admin';
import AppRoute from '../../enums/routes';

const LoginForm: React.FC = () => {
  const history = useHistory();
  const isFetching = useIsFetching();
  const { setToken } = useToken();
  const { setAdminDetails, setIsAdmin } = useContext(AdminDetailsContext);

  const [loginFormValues, setLoginFormValues] = useState<LoginFormValuesProps>(
    {} as LoginFormValuesProps
  );
  const [isAuthorized, setAuthorized] = useState<boolean>(false);

  const {
    handleSubmit,
    handleChange,
    validateField,
    values: { email, password },
    errors
  } = useFormik<LoginFormValuesProps>({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: loginFormSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      setLoginFormValues({ email: values.email, password: values.password });
    }
  });

  useLogin(
    { email: loginFormValues.email, password: loginFormValues.password },
    {
      onSuccess: ({ access_token: accessToken }: Token) => {
        setToken(accessToken);
        fetchData();
      },
      onError: (err: AxiosError) =>
        notify({ type: 'error', message: err.response?.data.message })
    }
  );

  const { refetch: fetchData } = useGetAdminDetails({
    onSuccess: ({
      firstName,
      lastName,
      picture,
      authorities
    }: AdminDetails) => {
      if (allowedRoles.some((element) => authorities.includes(element))) {
        setAuthorized(true);
        notify({ title: 'Success!', message: 'Login authorization success' });
        setTimeout(() => {
          setAdminDetails({
            firstName,
            lastName,
            picture,
            authorities
          });
          if (authorities.includes(AdminRole)) {
            setIsAdmin(true);
          }
          history.push(AppRoute.Dashboard);
        }, 1000);
      } else {
        notify({ type: 'error', message: 'Access denied' });
        setToken('');
      }
    },
    onError: (err: AxiosError) => {
      setToken('');
      notify({ type: 'error', message: err.response?.data.message });
    }
  });

  return (
    <div className="p-6 bg-white w-full md:w-480 m-6">
      <h2 className="mb-6 text-rp-black">Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="space-y-6 mb-6">
          <Input
            name="email"
            label="Email address"
            placeholder="xyz@abc.com"
            value={email}
            onChange={handleChange}
            onBlur={() => {
              validateField('email');
              if (password) {
                validateField('password');
              }
            }}
            error={errors.email}
          />
          <Input
            name="password"
            label="Password"
            type="password"
            placeholder="xxxxx"
            value={password}
            onChange={handleChange}
            onBlur={() => validateField('password')}
            error={errors.password}
          />
          <Button
            type="submit"
            primary
            loading={isFetching !== 0}
            disabled={isAuthorized}
          >
            Sign In
          </Button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
