import React from 'react';
import { CardRowValueProps } from '../../containers/dashboard/helpers';

interface DetailsCardProps {
  title: string;
  isLoading?: boolean;
  contents: CardRowValueProps[];
}

const DetailsCard: React.FC<DetailsCardProps> = ({
  title,
  isLoading = false,
  contents = []
}) => {
  return (
    <div className="bg-white shadow-sm w-full text-rp-black overflow-auto">
      <h2 className="p-4">{title}</h2>
      {isLoading ? (
        <div className="text-center">Loading...</div>
      ) : (
        <div className="border-t border-rp-grey p-2">
          <dl className="lg:divide-y lg:divide-rp-grey text-sm">
            {contents.map((row) => (
              <div
                className="p-3 lg:grid lg:grid-cols-2 lg:gap-2 px-4"
                key={row.key}
              >
                <dt className="text-rp-neutral">{row.name}</dt>
                <dd className="mt-1 lg:mt-0">{row.value}</dd>
              </div>
            ))}
          </dl>
        </div>
      )}
    </div>
  );
};

export default DetailsCard;
