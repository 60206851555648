import React, { useState } from 'react';
import { emptyUserDetails, UserDetails } from '../interfaces/user';

export interface UserDetailsInterface {
  userDetails: UserDetails;
  setUserDetails: (userDetails: UserDetails) => void;
}

export const UserDetailsContext = React.createContext<UserDetailsInterface>(
  {} as UserDetailsInterface
);

function UserDetailsProvider({ children }: { children: React.ReactNode }) {
  const [userDetails, setUserDetails] = useState<UserDetails>(emptyUserDetails);

  const value = { userDetails, setUserDetails };

  return (
    <UserDetailsContext.Provider value={value}>
      {children}
    </UserDetailsContext.Provider>
  );
}

export default UserDetailsProvider;
