export enum Role {
  ADMIN = 'ADMIN',
  OFFICE = 'OFFICE',
  TEAM = 'TEAM',
  UNKNOWN = 'UNKNOWN'
}

export const allowedRoles = [Role.ADMIN, Role.OFFICE];

export const AdminRole = Role.ADMIN;

export interface AdminDetails {
  picture: string;
  firstName: string;
  lastName: string;
  authorities: Role[];
}

export interface Admin {
  picture: string;
  firstName: string;
  lastName: string;
  authorities: Role[];
}

export const emptyAdminDetails: Admin = {
  picture: '',
  firstName: '',
  lastName: '',
  authorities: []
};
