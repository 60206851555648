import BankDetailsTypeCell from 'components/Table/cells/BankDetailsTypeCell';
import TransactionHashCell from 'components/Table/cells/TransactionHashCell';
import SelectColumnFilter from 'components/Table/filters/SelectColumnFilter';
import React, { useContext, useState, useEffect } from 'react';
import { CellProps, Column } from 'react-table';
import Table from '../../../components/Table';
import { UserDetailsContext } from '../../../contexts/userContext';
import TransactionType from '../../../enums/transactionType';
import { TablePaginateProps } from '../../../interfaces/shared';
import useGetTransactionHistory from '../../../services/api/user/getTransactionHistory';
import { ISellTransactionData } from './helpers';

const SellTransactionTable: React.FC = () => {
  const {
    userDetails: { userId }
  } = useContext(UserDetailsContext);

  const [paginateOptions, setPaginateOptions] = useState<TablePaginateProps>({
    page: 0,
    size: 5,
    sort: 'created,desc'
  });

  const { data, refetch } = useGetTransactionHistory(
    userId,
    TransactionType.SELL,
    {
      ...paginateOptions
    }
  );

  const columns: Column<ISellTransactionData>[] = [
    { Header: 'ID', accessor: 'id' },
    {
      Header: 'Transaction Hash',
      accessor: 'hash',
      Cell: ({ row: { original } }: CellProps<ISellTransactionData>) => (
        <TransactionHashCell data={original} refetchData={refetch} />
      )
    },
    { Header: 'Created', accessor: 'created' },
    {
      Header: 'Type',
      accessor: 'type',
      Filter: SelectColumnFilter,
      Cell: BankDetailsTypeCell
    },
    { Header: 'Status', accessor: 'status', Filter: SelectColumnFilter },
    { Header: 'AUD $', accessor: 'audAmount' },
    { Header: 'Fiat', accessor: 'fiatCurrency', Filter: SelectColumnFilter },
    { Header: 'Crypto Value', accessor: 'cryptoAmount' },
    {
      Header: 'Crypto',
      accessor: 'cryptoCurrency',
      Filter: SelectColumnFilter,
      filter: 'equals'
    },
    {
      Header: 'Routed Exchange',
      accessor: 'routedExchange',
      Filter: SelectColumnFilter
    },
    {
      Header: 'Crypto Network',
      accessor: 'cryptoNetwork',
      Filter: SelectColumnFilter
    }
  ];

  useEffect(() => {
    if (data && data.content.length > 0) {
      data.content.forEach((tx: ISellTransactionData) => {
        const { bankDetails } = tx;
        Object.entries(bankDetails).forEach(([key, value]) => {
          if (!value) {
            delete bankDetails[key];
          }
        });
      });
    }
  }, [data]);

  return (
    <Table
      name="Sell Transaction"
      data={data?.content || []}
      columns={columns}
      paginateOptions={paginateOptions}
      totalPages={data?.totalPages || 0}
      refetchData={(pageIndex: number, pageSize: number) => {
        setPaginateOptions({
          ...paginateOptions,
          page: pageIndex,
          size: pageSize
        });
      }}
    />
  );
};

export default SellTransactionTable;
