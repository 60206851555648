import React, { ReactNode, useState } from 'react';
import { Admin, emptyAdminDetails } from '../interfaces/admin';

export interface AdminDetailsProps {
  adminDetails: Admin;
  setAdminDetails: (adminDetails: Admin) => void;
  isAdmin: boolean;
  setIsAdmin: (isAdmin: boolean) => void;
}

export const AdminDetailsContext = React.createContext<AdminDetailsProps>(
  {} as AdminDetailsProps
);

function AdminDetailsProvider({ children }: { children: ReactNode }) {
  const [adminDetails, setAdminDetails] = useState<Admin>(emptyAdminDetails);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const value = { adminDetails, setAdminDetails, isAdmin, setIsAdmin };

  return (
    <AdminDetailsContext.Provider value={value}>
      {children}
    </AdminDetailsContext.Provider>
  );
}

export default AdminDetailsProvider;
