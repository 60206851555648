import React from 'react';
import { Redirect } from 'react-router-dom';
import LoginForm from '../../containers/login-form';
import AppRoute from '../../enums/routes';
import useToken from '../../hooks/useToken';

const Login: React.FC = () => {
  const { isAuthenticated } = useToken();
  const isAuth = isAuthenticated();

  if (isAuth) {
    return <Redirect to={AppRoute.Dashboard} />;
  }

  return (
    <div className="h-screen bg-rp-grey flex items-center justify-center">
      <LoginForm />
    </div>
  );
};

export default Login;
