import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import Monoova from 'pages/Monoova';
import Dashboard from '../../pages/Dashboard';
import ProtectedRoute from '../ProtectedRoutes';
import AppRoute from '../../enums/routes';
import RootContainer from '../../containers';

const PrivateRoutes: React.FC = () => {
  return (
    <RootContainer>
      <Switch>
        <ProtectedRoute path={AppRoute.Dashboard} exact component={Dashboard} />
        <ProtectedRoute path={AppRoute.Monoova} exact component={Monoova} />
        <Redirect to={AppRoute.Login} />
      </Switch>
    </RootContainer>
  );
};

export default PrivateRoutes;
