import * as yup from 'yup';

export interface LoginFormValuesProps {
  email: string;
  password: string;
}

export const loginFormSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email must be valid')
    .required('Email is required'),
  password: yup.string().required('Password is required')
});

export interface Token {
  scope: string;
  ['id_token']: string;
  error: string;
  ['access_token']: string;
  ['expires_in']: string;
  tokenType: string;
}
