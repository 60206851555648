import React from 'react';
import FraudTx from './fraud-transaction';

const MonoovaContainer: React.FC = () => {
  return (
    <>
      <h1 className="text-2xl font-semibold text-rp-black mb-4">Monoova</h1>
      <FraudTx />
    </>
  );
};

export default MonoovaContainer;
