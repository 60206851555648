import axios from 'axios';
import { useQuery } from 'react-query';
import { TablePaginateProps } from '../../../interfaces/shared';

const getTransactionHistory = async (
  userId: string,
  transactionType: string,
  paginate: TablePaginateProps
) => {
  const { page, size, sort } = paginate;
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/office/customer/dashboard/user/${userId}/transaction/${transactionType}`,
    {
      params: { page, size, sort },
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }
  );
  return data;
};

const useGetTransactionHistory = (
  userId: string,
  transactionType: string,
  paginate: TablePaginateProps,
  props = {}
) =>
  useQuery(
    ['getTransactionHistory', { userId, transactionType, ...paginate }],
    () => getTransactionHistory(userId, transactionType, paginate),
    {
      enabled: !!userId,
      retry: false,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      ...props
    }
  );

export default useGetTransactionHistory;
