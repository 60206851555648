export interface IPlatformUsage {
  signUpTime: string;
  lastLogin: string;
  lastLoginIp: string;
  transactionSummary: {
    totalVolume: number;
    totalNumberOfTransactions: number;
    lastTransactionType: string;
  };
  onfidoKycUrl: string;
  zendeskLink: string;
}

export const defaultRowValues = [
  { key: 'signupTime', name: 'Signup Time', value: '' },
  { key: 'lastLogin', name: 'Last Login', value: '' },
  {
    key: 'totalVolume',
    name: 'Total Volume',
    value: ''
  },
  {
    key: 'totalNumberOfTransactions',
    name: 'No. of Transactions',
    value: ''
  },
  {
    key: 'lastTransactionType',
    name: 'Last Transaction Type',
    value: ''
  },
  {
    key: 'lastLoginIp',
    name: 'Last Login IP',
    value: ''
  },
  {
    key: 'lastLoginLocation',
    name: 'Last Login Location',
    value: ''
  },
  {
    key: 'onfidoKycUrl',
    name: 'Onfido KYC Link',
    value: ''
  },
  {
    key: 'zendeskLink',
    name: 'Zendesk Link',
    value: ''
  }
];
