import axios from 'axios';
import { useQuery } from 'react-query';
import { EnterDetailsFormValuesProps } from '../../../containers/dashboard/enter-details/helpers';

const getUserDetails = async (body: EnterDetailsFormValuesProps) => {
  const { txHash, email, txId } = body;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/office/customer/dashboard/user`,
    {
      params: { txHash, email, txId, connection: 'AUTH_CONNECTION' },
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }
  );
  return data;
};

const useGetUserDetails = (body: EnterDetailsFormValuesProps, props = {}) =>
  useQuery(['getUserDetails', body], () => getUserDetails(body), {
    enabled: body.txHash !== '' || body.email !== '' || body.txId !== '',
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...props
  });

export default useGetUserDetails;
