import axios from 'axios';
import { useQuery } from 'react-query';

interface AssignTxHashProps {
  id: string;
  hash: string;
}

const assignTxHash = async ({ id, hash }: AssignTxHashProps) => {
  // Todo - update endpoint once ready for cs
  const { status } = await axios.patch(
    `${process.env.REACT_APP_API_URL}/admin/txs/id/${id}/hash/${hash}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }
  );
  return status === 200;
};

const useAssignTxHash = ({ id, hash }: AssignTxHashProps, props = {}) =>
  useQuery(['assignTxHash', { id, hash }], () => assignTxHash({ id, hash }), {
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
    ...props
  });

export default useAssignTxHash;
