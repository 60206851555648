export enum CryptoNetwork {
  BITCOIN = 'BITCOIN',
  ETHEREUM = 'ETHEREUM'
}

export enum CryptoNetworkView {
  BITCOIN = 'https://www.blockchain.com/btc/tx/',
  ETHEREUM = 'https://etherscan.io/tx/'
}

export enum EthereumNetwork {
  RinkebyTestNet = 'https://rinkeby.etherscan.io/tx/',
  RopstenTestNet = 'https://ropsten.etherscan.io/tx/',
  GoerliTestNet = 'https://goerli.etherscan.io/tx/',
  KovanTestNet = 'https://kovan.etherscan.io/tx/'
}

export interface AssignHashData {
  id: string;
  type: string;
  audAmount: number;
  fiatCurrency: string;
  cryptoAmount: number;
  cryptoCurrency: string;
}

export interface BlockchainViewData {
  hash: string;
  cryptoNetwork: string;
  routedExchange: string;
}

export interface TransactionHashCellData {
  id: string;
  type: string;
  audAmount: number;
  fiatCurrency: string;
  cryptoAmount: number;
  cryptoCurrency: string;
  hash: string;
  cryptoNetwork: string;
  routedExchange: string;
}
