import { notify } from '@relaypay/uikit';
import { AxiosError } from 'axios';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { CardRowValueProps } from 'containers/dashboard/helpers';
import { UserDetailsContext } from 'contexts/userContext';
import React, { useContext, useState } from 'react';
import useLoadMonoovaTx from 'services/api/user/loadMonoovaTx';
import { MonoovaTxData, StatusCellData } from '../helpers';
import NameMismatchModal from '../NameMismatchModal';

interface NameMismatchProps {
  data: StatusCellData;
}

const NameMismatch: React.FC<NameMismatchProps> = ({
  data: { id, type, audAmount, fiatCurrency, cryptoAmount, cryptoCurrency }
}) => {
  const {
    userDetails: { firstName, lastName }
  } = useContext(UserDetailsContext);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [bankName, setBankName] = useState<string>();

  const { refetch: getBankName } = useLoadMonoovaTx(
    { txId: id },
    {
      onSuccess: ({ sourceAccountName, remitterName }: MonoovaTxData) => {
        setBankName(`${sourceAccountName} ${remitterName}`);
        setShowModal(true);
      },
      onError: (err: AxiosError) => {
        notify({ type: 'error', message: err.response?.data.message });
      }
    }
  );

  const contents: CardRowValueProps[] = [
    { key: 'name', name: 'Onfido Name', value: `${firstName} ${lastName}` },
    { key: 'name', name: 'Bank Name', value: bankName },
    { key: 'id', name: 'Id', value: id },
    { key: 'type', name: 'Type', value: type },
    { key: 'fiat', name: 'Fiat', value: `${audAmount} ${fiatCurrency}` },
    {
      key: 'crypto',
      name: 'Crypto',
      value: `${cryptoAmount} ${cryptoCurrency}`
    }
  ];

  const handleClick = () => {
    getBankName();
  };

  return (
    <>
      <Button secondary size="small" onClick={handleClick}>
        Approve
      </Button>
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <NameMismatchModal data={contents} id={id} />
      </Modal>
    </>
  );
};

export default NameMismatch;
