import React from 'react';
import SidebarDesktop from './desktop';
import SidebarMobile from './mobile';

const SideBarContainer: React.FC = () => {
  return (
    <>
      <SidebarMobile />
      <SidebarDesktop />
    </>
  );
};

export default SideBarContainer;
