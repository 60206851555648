import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import AppRoute from '../../enums/routes';
import useToken from '../../hooks/useToken';

interface ProtectedRouteProps extends RouteProps {
  redirectPath?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  redirectPath = AppRoute.Login,
  ...routeProps
}) => {
  const { isAuthenticated } = useToken();
  const isAuth = isAuthenticated();

  return isAuth ? (
    <Route {...routeProps} component={Component} />
  ) : (
    <Redirect to={{ pathname: redirectPath }} />
  );
};

export default ProtectedRoute;
