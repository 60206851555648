import React from 'react';
import { HeaderGroup } from 'react-table';
import { IKeyValueMap } from '../../../../interfaces/shared';

interface FilterProps<T extends IKeyValueMap> {
  column: HeaderGroup<T>;
}

function DefaultColumnFilter<T extends IKeyValueMap>({
  column: { filterValue, preFilteredRows, setFilter }
}: FilterProps<T>) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      className="border rounded p-1 text-rp-black focus:outline-none"
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

export default DefaultColumnFilter;
