import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { AdminDetailsContext } from '../contexts/adminContext';
import AppRoute from '../enums/routes';
import useToken from '../hooks/useToken';
import {
  AdminDetails,
  AdminRole,
  allowedRoles,
  emptyAdminDetails
} from '../interfaces/admin';
import { getAdminDetails } from '../services/api/admin/getAdminDetails';
import AppLayout from './app-layout';

const RootContainer: React.FC = (props) => {
  const {
    adminDetails: { firstName },
    setAdminDetails,
    setIsAdmin
  } = useContext(AdminDetailsContext);
  const { isAuthenticated, setToken } = useToken();

  useEffect(() => {
    const isAuth = isAuthenticated();
    if (isAuth && !firstName) {
      getAdminDetails().then(
        ({
          firstName: fName,
          lastName,
          picture,
          authorities
        }: AdminDetails) => {
          if (allowedRoles.some((element) => authorities.includes(element))) {
            setAdminDetails({
              firstName: fName,
              lastName,
              picture,
              authorities
            });
            if (authorities.includes(AdminRole)) {
              setIsAdmin(true);
            }
          } else {
            setToken('');
            setAdminDetails(emptyAdminDetails);
            <Redirect to={AppRoute.Login} />;
          }
        }
      );
    }
  }, []);

  return <AppLayout {...props} />;
};

export default RootContainer;
