import TransactionStatus from 'enums/transactionStatus';
import React from 'react';
import { StatusCellData } from './helpers';
import NameMismatch from './NameMismatch';

interface StatusCellProps {
  data: StatusCellData;
}

const StatusCell: React.FC<StatusCellProps> = ({ data: { status }, data }) => {
  return (
    <>
      {status}
      {status === TransactionStatus.TX_NOT_ALLOWED && (
        <span className="pl-4">
          <NameMismatch data={data} />
        </span>
      )}
    </>
  );
};

export default StatusCell;
