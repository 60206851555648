import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import AnchorLink from 'components/AnchorLink';
import logo from '../../../public/images/relaypay.svg';
import navigation, { quickLinks } from './helpers';
import useToken from '../../hooks/useToken';
import AppRoute from '../../enums/routes';
import { AdminDetailsContext } from '../../contexts/adminContext';
import { emptyAdminDetails } from '../../interfaces/admin';

const Sidebar: React.FC = () => {
  const location = useLocation();
  const { setToken } = useToken();
  const {
    adminDetails: { picture, firstName, lastName },
    setAdminDetails,
    setIsAdmin
  } = useContext(AdminDetailsContext);

  return (
    <>
      <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
        <div className="flex items-center flex-shrink-0 px-4">
          <img src={logo} alt="relaypay logo" />
        </div>
        <nav className="mt-5 px-2">
          <ul className="flex-1 space-y-1">
            {navigation.map((item) => {
              return (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className={classNames(
                      'group flex items-center p-2 text-sm font-medium rounded-md',
                      {
                        'text-rp-black': location.pathname === item.href,
                        'text-rp-neutral hover:text-rp-black':
                          location.pathname !== item.href
                      }
                    )}
                  >
                    <item.icon
                      aria-hidden="true"
                      className="mr-3 flex-shrink-0 h-6 w-6"
                    />
                    {item.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
      <div className="border-t-2 p-4 text-sm">
        <h1 className="font-medium">Quick Links</h1>
        <ul className="pt-2 pl-4 space-y-1 flex flex-col">
          {quickLinks.map(({ name, href }) => (
            <AnchorLink key={name} href={href}>
              {name}
            </AnchorLink>
          ))}
        </ul>
      </div>
      <div className="flex-shrink-0 flex p-4 border-t-2">
        <div className="flex-shrink-0 group block">
          <div className="flex items-center">
            <div>
              <img
                src={picture}
                alt="admin"
                className="inline-block h-9 w-9 rounded-full"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-base font-medium text-rp-black">
                {firstName} {lastName}
              </p>
              <Link
                to={AppRoute.Login}
                className="text-sm font-medium text-rp-neutral group-hover:text-rp-black cursor"
                onClick={() => {
                  setToken('');
                  setAdminDetails(emptyAdminDetails);
                  setIsAdmin(false);
                }}
              >
                Log out
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
