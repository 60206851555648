export interface UserDetails {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  dob: string;
  address: {
    country: string;
    state: string;
    town: string;
    street: string;
    postcode: string;
    buildingName: string;
    buildingNumber: string;
  };
  kycStatus: {
    status: string;
    ageGroup: string;
  };
  referralInfo: {
    referredBy: string;
    referralEarned: string;
    referralsMade: string;
  };
  accountTypes: string[];
  associatedEntity: string;
  userDiscounts: UserDiscounts[];
}

export interface UserDiscounts {
  discountType: string;
  discountCryptoGroup: string;
  amount: string;
  cryptoCurrency: string | null;
  maxLimit: string;
}

export const emptyUserDetails: UserDetails = {
  userId: '',
  firstName: '',
  lastName: '',
  email: '',
  dob: '',
  address: {
    country: '',
    state: '',
    town: '',
    street: '',
    postcode: '',
    buildingName: '',
    buildingNumber: ''
  },
  kycStatus: { status: '', ageGroup: '' },
  referralInfo: { referredBy: '', referralEarned: '', referralsMade: '' },
  accountTypes: [],
  associatedEntity: '',
  userDiscounts: []
};
