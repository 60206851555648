import axios from 'axios';
import { useQuery } from 'react-query';

const verifyOldUser = async (email: string) => {
  const { data } = await axios.patch(
    `${
      process.env.REACT_APP_API_URL
    }/office/user/extra/approve?email=${encodeURIComponent(email)}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }
  );
  return data;
};

const useVerifyOldUser = (email: string, props = {}) =>
  useQuery(['verifyOldUser', { email }], () => verifyOldUser(email), {
    enabled: false,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...props
  });

export default useVerifyOldUser;
