import StatusCell from 'components/Table/cells/StatusCell';
import TransactionHashCell from 'components/Table/cells/TransactionHashCell';
import SelectColumnFilter from 'components/Table/filters/SelectColumnFilter';
import React, { useContext, useState } from 'react';
import { CellProps, Column } from 'react-table';
import Table from '../../../components/Table';
import { UserDetailsContext } from '../../../contexts/userContext';
import TransactionType from '../../../enums/transactionType';
import { TablePaginateProps } from '../../../interfaces/shared';
import useGetTransactionHistory from '../../../services/api/user/getTransactionHistory';
import { IBuyTransactionData } from './helpers';

const BuyTransactionTable: React.FC = () => {
  const {
    userDetails: { userId }
  } = useContext(UserDetailsContext);

  const [paginateOptions, setPaginateOptions] = useState<TablePaginateProps>({
    page: 0,
    size: 5,
    sort: 'created,desc'
  });

  const { data, refetch } = useGetTransactionHistory(
    userId,
    TransactionType.BUY,
    {
      ...paginateOptions
    }
  );

  const columns: Column<IBuyTransactionData>[] = [
    { Header: 'ID', accessor: 'id' },
    {
      Header: 'Transaction Hash',
      accessor: 'hash',
      Cell: ({ row: { original } }: CellProps<IBuyTransactionData>) => (
        <TransactionHashCell data={original} refetchData={refetch} />
      )
    },
    { Header: 'Created', accessor: 'created' },
    { Header: 'Type', accessor: 'type', Filter: SelectColumnFilter },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row: { original } }: CellProps<IBuyTransactionData>) => (
        <StatusCell data={original} />
      ),
      Filter: SelectColumnFilter
    },
    { Header: 'AUD $', accessor: 'audAmount' },
    { Header: 'Fiat', accessor: 'fiatCurrency', Filter: SelectColumnFilter },
    { Header: 'Crypto Value', accessor: 'cryptoAmount' },
    {
      Header: 'Crypto',
      accessor: 'cryptoCurrency',
      Filter: SelectColumnFilter,
      filter: 'equals'
    },
    {
      Header: 'Routed Exchange',
      accessor: 'routedExchange',
      Filter: SelectColumnFilter
    },
    {
      Header: 'Crypto Network',
      accessor: 'cryptoNetwork',
      Filter: SelectColumnFilter
    }
  ];

  return (
    <Table
      name="Buy Transaction"
      data={data?.content || []}
      columns={columns}
      paginateOptions={paginateOptions}
      totalPages={data?.totalPages || 0}
      refetchData={(pageIndex: number, pageSize: number) => {
        setPaginateOptions({
          ...paginateOptions,
          page: pageIndex,
          size: pageSize
        });
      }}
    />
  );
};

export default BuyTransactionTable;
