import React from 'react';
import { Row } from 'react-table';
import { IKeyValueMap } from '../../../../interfaces/shared';

export interface RowProps<T extends IKeyValueMap> {
  row: Row<T>;
}

function BankDetailsTypeCell<T extends IKeyValueMap>({
  row: {
    values: { type },
    original: { bankDetails },
    isExpanded,
    toggleRowExpanded
  }
}: RowProps<T>) {
  const displayBankDetails = () =>
    Object.entries(bankDetails).map(([key, value]) => (
      <li key={key}>
        <span>
          {key}: {value}
        </span>
      </li>
    ));

  return (
    <>
      <button
        type="button"
        className="hover:text-button-default-text-hover"
        onClick={() => toggleRowExpanded(!isExpanded)}
      >
        {type}
      </button>
      {isExpanded && displayBankDetails()}
    </>
  );
}

export default BankDetailsTypeCell;
