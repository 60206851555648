import axios from 'axios';
import { useQuery } from 'react-query';

const login = async (email: string, password: string) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL}/oauth/token`,
    { email, password }
  );
  return data;
};

const useLogin = (
  { email, password }: { email: string; password: string },
  props = {}
) =>
  useQuery(['login', { email, password }], () => login(email, password), {
    enabled: !!email && !!password,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    ...props
  });

export default useLogin;
