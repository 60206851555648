import axios from 'axios';
import { useQuery } from 'react-query';

const getPlatformUsage = async (userId: string) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/office/customer/dashboard/user/${userId}/platform-usage`,
    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
  );
  return data;
};

const useGetPlatformUsage = ({ userId }: { userId: string }, props = {}) =>
  useQuery(['getPlatformUsage', { userId }], () => getPlatformUsage(userId), {
    enabled: !!userId,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...props
  });

export default useGetPlatformUsage;
