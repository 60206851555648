import React from 'react';
import {
  AssignHashData,
  BlockchainViewData,
  TransactionHashCellData
} from './helpers';
import AssignHash from './AssignHash';
import BlockchainView from './BlockchainView';

interface TransactionHashCellProps {
  data: TransactionHashCellData;
  refetchData: () => void;
}

const TransactionHashCell: React.FC<TransactionHashCellProps> = ({
  data: {
    id,
    type,
    hash,
    audAmount,
    fiatCurrency,
    cryptoAmount,
    cryptoCurrency,
    cryptoNetwork,
    routedExchange
  },
  refetchData
}) => {
  if (!hash) {
    const data: AssignHashData = {
      id,
      type,
      audAmount,
      fiatCurrency,
      cryptoAmount,
      cryptoCurrency
    };
    return <AssignHash data={data} refetchData={refetchData} />;
  }

  const data: BlockchainViewData = { hash, cryptoNetwork, routedExchange };
  return <BlockchainView data={data} />;
};

export default TransactionHashCell;
