import React from 'react';
import UserDetailsProvider from '../../contexts/userContext';
import BuyTransactionTable from './buy-transaction';
import EnterDetails from './enter-details';
import MerchantTransactionTable from './merchant-transaction';
import PlatformUsage from './platform-usage';
import SellTransactionTable from './sell-transaction';
import UserDetails from './user-details';
import ZendeskTicketsTable from './zendesk-tickets';

const DashboardContainer: React.FC = () => {
  return (
    <UserDetailsProvider>
      <h1 className="text-2xl font-semibold text-rp-black mb-4">Dashboard</h1>
      <div className="space-y-4">
        <EnterDetails />
        <div className="flex space-x-2">
          <UserDetails />
          <PlatformUsage />
        </div>
        <SellTransactionTable />
        <BuyTransactionTable />
        <MerchantTransactionTable />
        <ZendeskTicketsTable />
      </div>
    </UserDetailsProvider>
  );
};

export default DashboardContainer;
