import React from 'react';
import Sidebar from '../../../components/Sidebar';

const SidebarDesktop: React.FC = () => {
  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-72">
        <div className="flex flex-col h-0 flex-1 bg-white">
          <Sidebar />
        </div>
      </div>
    </div>
  );
};

export default SidebarDesktop;
