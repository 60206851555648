import React, { InputHTMLAttributes } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string;
}

const Input: React.FC<InputProps> = ({
  label = '',
  placeholder = '',
  name = '',
  value = '',
  type = 'text',
  error,
  ...props
}) => {
  return (
    <label
      htmlFor={name}
      className={classNames(
        'w-full relative block border-2 border-rp-grey text-sm text-rp-black p-2 h-14',
        {
          border: error,
          'border-rp-error': error
        }
      )}
    >
      {label}
      <input
        type={type}
        name={name}
        id={name}
        className="w-full font-medium pr-10 focus:outline-none focus:bg-white"
        value={value}
        placeholder={placeholder}
        aria-invalid="true"
        {...props}
      />
      {error && (
        <>
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-rp-error"
              aria-hidden="true"
            />
          </div>
          <div className="absolute text-rp-error text-xs mt-2">{error}</div>
        </>
      )}
    </label>
  );
};

export default Input;
