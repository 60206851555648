const defaultRowValues = [
  { key: 'firstName', name: 'First Name', value: '' },
  { key: 'lastName', name: 'Last Name', value: '' },
  { key: 'email', name: 'Email', value: '' },
  { key: 'dob', name: 'DOB', value: '' },
  {
    key: 'address',
    name: 'Address',
    value: ''
  },
  {
    key: 'accountType',
    name: 'Account Types',
    value: ''
  },
  {
    key: 'associatedEntity',
    name: 'Associated Entity',
    value: ''
  },
  {
    key: 'kycStatus',
    name: 'KYC Status',
    value: ''
  },
  {
    key: 'userDiscounts',
    name: 'Discount Rate',
    value: ''
  },
  {
    key: 'referredBy',
    name: 'Referred By',
    value: ''
  },
  {
    key: 'referralEarned',
    name: 'Total Referral $ earned',
    value: ''
  },
  {
    key: 'referralsMade',
    name: 'Total Referrals made',
    value: ''
  }
];

const getFullAddress = ({
  buildingNumber,
  buildingName,
  street,
  town,
  state,
  country,
  postcode
}: {
  buildingNumber: string;
  buildingName: string;
  street: string;
  town: string;
  state: string;
  country: string;
  postcode: string;
}) => {
  let fullAddress = '';
  if (buildingNumber) {
    fullAddress += buildingNumber;
  }
  if (buildingName) {
    fullAddress += ` ${buildingName}`;
  }
  if (street) {
    fullAddress += `/${street}`;
  }
  if (town) {
    fullAddress += ` ${town}`;
  }
  if (state) {
    fullAddress += ` ${state}`;
  }
  if (country) {
    fullAddress += ` ${country}`;
  }
  if (postcode) {
    fullAddress += ` ${postcode}`;
  }
  return fullAddress;
};

enum DiscountType {
  PERCENTAGE = '%',
  BPS = 'BPS',
  EMPLOYEE_DISCOUNT = '% Employee Discount',
  OTC = 'OTC'
}

const discountText: { [key: string]: string } = {
  PERCENTAGE: DiscountType.PERCENTAGE,
  BPS: DiscountType.BPS,
  EMPLOYEE_DISCOUNT: DiscountType.EMPLOYEE_DISCOUNT,
  OTC: DiscountType.OTC
};

const getDiscountGroup = (
  cryptoGroup: string,
  cryptoCurrency: string | null
) => {
  if (cryptoGroup === 'SPECIFIC_COIN') return cryptoCurrency;
  return cryptoGroup;
};

export { defaultRowValues, discountText, getFullAddress, getDiscountGroup };
