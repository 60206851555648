import * as yup from 'yup';

export interface EnterDetailsFormValuesProps {
  txHash: string;
  email: string;
  txId: string;
}

export const enterDetailsFormSchema = yup.object().shape({
  txHash: yup.string(),
  email: yup.string().email('Email must be valid'),
  txId: yup.string()
});
