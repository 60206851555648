import { HomeIcon, LibraryIcon } from '@heroicons/react/outline';
import AppRoute from '../../enums/routes';

const navigation = [
  {
    name: 'Dashboard',
    href: AppRoute.Dashboard,
    icon: HomeIcon
  },
  {
    name: 'Monoova',
    href: AppRoute.Monoova,
    icon: LibraryIcon
  }
];

export const quickLinks = [
  {
    name: 'Retool',
    href: 'https://relaypay.retool.com/'
  },
  {
    name: 'Auth0',
    href: 'https://manage.auth0.com/dashboard/au/relaypay/'
  },
  {
    name: 'Onfido',
    href: 'https://dashboard.onfido.com/analytics'
  },
  {
    name: 'BronID',
    href: 'https://portal.bronid.com/'
  },
  {
    name: 'MerkleScience',
    href: 'https://www.app.merklescience.com/dashboard/'
  },
  {
    name: 'AUSTRAC',
    href: 'https://online.austrac.gov.au/ao/pages/mainPage.seam?cid=21177'
  }
];

export default navigation;
