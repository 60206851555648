import { notify } from '@relaypay/uikit';
import { AxiosError } from 'axios';
import Button from 'components/Button';
import DetailsCard from 'components/DetailsCard';
import Input from 'components/InputField';
import { CardRowValueProps } from 'containers/dashboard/helpers';
import { useFormik } from 'formik';
import React from 'react';
import useAssignTxHash from 'services/api/user/assignTxHash';
import * as yup from 'yup';

const AssignHashFormSchema = yup.object().shape({
  hash: yup.string().required('Hash is required')
});

interface AssignHashModalProps {
  id: string;
  data: CardRowValueProps[];
  refetchData: () => void;
}

const AssignHashModal: React.FC<AssignHashModalProps> = ({
  id,
  data,
  refetchData
}) => {
  const {
    values: { hash },
    errors,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: {
      hash: ''
    },
    validationSchema: AssignHashFormSchema,
    onSubmit: () => {
      assingHash();
    }
  });

  const {
    isLoading,
    refetch: assingHash,
    isSuccess
  } = useAssignTxHash(
    { id, hash },
    {
      onSuccess: () => {
        notify({ message: 'Hash assigned!' });
        refetchData();
      },
      onError: (err: AxiosError) => {
        notify({ type: 'error', message: err.response?.data.message });
      }
    }
  );

  return (
    <>
      <DetailsCard title="Assign Hash" contents={data} />
      <form onSubmit={handleSubmit} className="flex flex-col space-y-6">
        <Input
          name="hash"
          label="Hash"
          value={hash}
          onChange={handleChange}
          error={errors.hash}
          disabled={isSuccess}
        />
        <Button
          primary
          size="large"
          type="submit"
          loading={isLoading}
          disabled={isSuccess}
        >
          Submit
        </Button>
      </form>
    </>
  );
};

export default AssignHashModal;
