/* eslint-disable react/button-has-type */
import classNames from 'classnames';
import React from 'react';
import Loader from '../Loader';

const PRIMARY_CLASSES =
  'bg-button-primary text-button-primary-text hover:bg-button-primary-hover active:bg-button-primary-active disabled:bg-button-primary-disabled disabled:text-button-primary-text-disabled';
const SECONDARY_CLASSES =
  'border border-rp-neutral9 text-rp-neutral9 hover:border-rp-blue2 hover:text-rp-blue2';
const ICON_CLASSES = 'flex p-0 items-center';
const DEFAULT_CLASSES =
  'hover:text-button-default-text-hover active:text-button-default-text-active disabled:text-button-text-disabled';

type ButtonSize = 'small' | 'medium' | 'large';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  loading?: boolean;
  icon?: boolean;
  size?: ButtonSize;
}

const Button: React.FC<ButtonProps> = ({
  primary,
  secondary,
  loading,
  icon = null,
  disabled,
  size = 'large',
  children,
  ...props
}) => {
  return (
    <button
      {...props}
      className={classNames(props.className, 'rounded-sm', {
        [PRIMARY_CLASSES]: primary,
        [SECONDARY_CLASSES]: secondary,
        [DEFAULT_CLASSES]: !primary && !secondary,
        [ICON_CLASSES]: icon,
        'cursor-default': disabled,
        'px-4 py-1': size === 'small',
        'p-4 w-full': size === 'large'
      })}
      disabled={loading || disabled}
    >
      {!loading && icon && <div className="mr-2">{icon}</div>}
      {!loading && children}
      {loading && (
        <div className="max-h-6">
          <Loader className="m-auto" />
        </div>
      )}
    </button>
  );
};

export default Button;

export { Button };
