import axios from 'axios';
import { useQuery } from 'react-query';

const verifyBusinessAccount = async (email: string) => {
  const { data } = await axios.patch(
    `${process.env.REACT_APP_API_URL}/office/user/buisness/approve`,
    {
      headers: {
        params: { email },
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }
  );
  return data;
};

const useVerifyBusinessAccount = (email: string, props = {}) =>
  useQuery(
    ['verifyBusinessAccount', { email }],
    () => verifyBusinessAccount(email),
    {
      enabled: false,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...props
    }
  );

export default useVerifyBusinessAccount;
