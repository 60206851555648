import Button from 'components/Button';
import Modal from 'components/Modal';
import { CardRowValueProps } from 'containers/dashboard/helpers';
import { UserDetailsContext } from 'contexts/userContext';
import React, { useContext, useState } from 'react';
import AssignHashModal from '../AssignHashModal';
import { AssignHashData } from '../helpers';

interface AssignHashProps {
  data: AssignHashData;
  refetchData: () => void;
}

const AssignHash: React.FC<AssignHashProps> = ({
  data: { id, type, audAmount, fiatCurrency, cryptoAmount, cryptoCurrency },
  refetchData
}) => {
  const {
    userDetails: { firstName, lastName }
  } = useContext(UserDetailsContext);

  const [showModal, setShowModal] = useState(false);

  const contents: CardRowValueProps[] = [
    { key: 'name', name: 'Name', value: `${firstName} ${lastName}` },
    { key: 'id', name: 'Id', value: id },
    { key: 'type', name: 'Type', value: type },
    { key: 'fiat', name: 'Fiat', value: `${audAmount} ${fiatCurrency}` },
    {
      key: 'crypto',
      name: 'Crypto',
      value: `${cryptoAmount} ${cryptoCurrency}`
    }
  ];

  return (
    <>
      <Button secondary size="small" onClick={() => setShowModal(true)}>
        Assign
      </Button>
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <AssignHashModal id={id} data={contents} refetchData={refetchData} />
      </Modal>
    </>
  );
};

export default AssignHash;
