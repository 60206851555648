import axios from 'axios';
import { useQuery } from 'react-query';

interface ConfirmFraud {
  monoovaId: string;
}

const confirmFraudTx = async ({ monoovaId }: ConfirmFraud) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL}/office/monoova/confirmed/fraud/${monoovaId}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }
  );
  return data;
};

const useConfirmFraudTx = ({ monoovaId }: ConfirmFraud, props = {}) =>
  useQuery(
    ['confirmFraudTx', { monoovaId }],
    () => confirmFraudTx({ monoovaId }),
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      ...props
    }
  );

export default useConfirmFraudTx;
