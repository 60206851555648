import axios from 'axios';
import { useQuery } from 'react-query';

const getLocation = async (ip: string) => {
  const {
    data: { city, country_name: countryName, region_name: regionName, zip }
  } = await axios.get(
    `http://api.ipstack.com/${ip}?access_key=${process.env.REACT_APP_IPSTACK_ACCESS_KEY}`
  );
  return `${city} ${regionName} ${countryName} ${zip}`;
};

const useGetLocation = ({ ip }: { ip: string }, props = {}) => {
  return useQuery(['getLocation', { ip }], () => getLocation(ip), {
    retry: false,
    enabled: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    ...props
  });
};

export default useGetLocation;
