import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, ReactNode } from 'react';
import { ReactComponent as CloseIcon } from '../../../public/images/icons/close.svg';

interface ModalProps {
  show: boolean;
  onClose: () => void;
  children: ReactNode;
}

const Modal: React.FC<ModalProps> = ({ show, onClose, children }) => {
  return (
    <Transition show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 lg:left-[300px] z-10 overflow-y-auto"
        onClose={onClose}
      >
        <div className="min-h-screen flex items-center justify-center">
          <Dialog.Overlay className="fixed inset-0 bg-rp-neutral9 opacity-20" />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md transition-all transform bg-white rounded-4 relative">
              <button
                type="button"
                className="absolute top-5 right-5"
                onClick={onClose}
              >
                <CloseIcon />
              </button>
              <div className="p-10 pt-8">{children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
