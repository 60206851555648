import axios from 'axios';
import PaymentAction from 'enums/paymentAction';
import { useQuery } from 'react-query';

interface ManualPayment {
  txId: string;
  action: PaymentAction;
}

const manualPayment = async ({ txId, action }: ManualPayment) => {
  const { data } = await axios.patch(
    `${process.env.REACT_APP_API_URL}/office/${action}/txs/id/${txId}/`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }
  );
  return data;
};

const useManualPayment = ({ txId, action }: ManualPayment, props = {}) =>
  useQuery(
    ['manualPayment', { txId, action }],
    () => manualPayment({ txId, action }),
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      ...props
    }
  );

export default useManualPayment;
