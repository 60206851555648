import AnchorLink from 'components/AnchorLink';
import React from 'react';
import {
  BlockchainViewData,
  CryptoNetwork,
  CryptoNetworkView,
  EthereumNetwork
} from '../helpers';

interface BlockchainViewProps {
  data: BlockchainViewData;
}

const BlockchainView: React.FC<BlockchainViewProps> = ({
  data: { hash, cryptoNetwork, routedExchange }
}) => {
  let link = CryptoNetworkView.ETHEREUM as string;
  if (cryptoNetwork === CryptoNetwork.BITCOIN) link = CryptoNetworkView.BITCOIN;
  else if (routedExchange in EthereumNetwork)
    link = EthereumNetwork[routedExchange as keyof typeof EthereumNetwork];

  return <AnchorLink href={link + hash}>{hash}</AnchorLink>;
};

export default BlockchainView;
