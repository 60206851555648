import React from 'react';

const MainContainer: React.FC = ({ children }) => {
  return (
    <div className="flex flex-col flex-1 overflow-hidden">
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 md:px-8 py-6">
          {children}
        </div>
      </main>
    </div>
  );
};

export default MainContainer;
