import React, { useContext, useState, useEffect } from 'react';
import numeral from 'numeral';
import Button from '../../../components/Button';
import DetailsCard from '../../../components/DetailsCard';
import { UserDetailsContext } from '../../../contexts/userContext';
import { CardRowValueProps } from '../helpers';
import {
  defaultRowValues,
  discountText,
  getDiscountGroup,
  getFullAddress
} from './helpers';
import { UserDiscounts } from '../../../interfaces/user';
import useVerifyOldUser from '../../../services/api/user/verfiyOldUser';
import useVerifyBusinessAccount from '../../../services/api/user/verifyBusinessAccount';
import useVerifyMerchant from '../../../services/api/user/verifyMerchant';

const UserDetails: React.FC = () => {
  const {
    userDetails: {
      userId,
      firstName,
      lastName,
      email,
      dob,
      address,
      accountTypes,
      associatedEntity,
      kycStatus: { status = '' } = {},
      userDiscounts,
      referralInfo: {
        referredBy = '',
        referralEarned = 0,
        referralsMade = 0
      } = {}
    }
  } = useContext(UserDetailsContext);

  const [userDetailsRow, setUserDetailsRow] = useState<CardRowValueProps[]>([]);

  const { refetch: verifyOldUser } = useVerifyOldUser(email, {
    onSuccess: (response: string) => {
      setUserDetailsRow(
        userDetailsRow.map((row) => {
          const newRow = row;
          if (newRow.key === 'kycStatus') {
            newRow.value = response;
          }
          return newRow;
        })
      );
    }
  });

  const { refetch: verifyBusinessAccount } = useVerifyBusinessAccount(email, {
    onSuccess: (response: string) => {
      setUserDetailsRow(
        userDetailsRow.map((row) => {
          const newRow = row;
          if (newRow.key === 'kycStatus') {
            newRow.value = response;
          }
          return newRow;
        })
      );
    }
  });

  const { refetch: verifyMerchant } = useVerifyMerchant(email, {
    onSuccess: (response: string) => {
      setUserDetailsRow(
        userDetailsRow.map((row) => {
          const newRow = row;
          if (newRow.key === 'kycStatus') {
            newRow.value = response;
          }
          return newRow;
        })
      );
    }
  });

  const getApproveButton = () => {
    const accountType = accountTypes[0];
    return (
      <div>
        <p className="inline mr-4">{status}</p>
        {accountType === 'INDIVIDUAL' &&
          status === 'EXTRA_MANUAL_CHECK_REQUIRED' && (
            <Button
              type="button"
              primary
              size="small"
              onClick={() => {
                verifyOldUser();
              }}
            >
              Approve
            </Button>
          )}
        {accountType !== 'INDIVIDUAL' && status !== 'CLEAR' && (
          <Button
            type="button"
            primary
            size="small"
            onClick={() => {
              if (accountType === 'BUSINESS') {
                verifyBusinessAccount();
              } else if (accountType === 'COMMERCE') {
                verifyMerchant();
              }
            }}
          >
            Approve
          </Button>
        )}
      </div>
    );
  };

  const getDiscounRates = (discountRates: UserDiscounts[]) =>
    discountRates.map((element) => {
      const {
        amount,
        cryptoCurrency,
        discountCryptoGroup,
        discountType,
        maxLimit
      } = element;
      return (
        <li
          key={
            discountType +
            discountCryptoGroup +
            cryptoCurrency +
            amount +
            maxLimit
          }
        >
          {+amount} {discountText[discountType]}{' '}
          {getDiscountGroup(discountCryptoGroup, cryptoCurrency)} (max{' '}
          {numeral(maxLimit).format('$0a')})
        </li>
      );
    });

  useEffect(() => {
    if (userId) {
      const discountRates = getDiscounRates(userDiscounts);

      const rowData = [
        { key: 'firstName', name: 'First Name', value: firstName },
        { key: 'lastName', name: 'Last Name', value: lastName },
        { key: 'email', name: 'Email', value: email },
        { key: 'dob', name: 'DOB', value: dob },
        {
          key: 'address',
          name: 'Address',
          value: getFullAddress(address)
        },
        {
          key: 'accountType',
          name: 'Account Types',
          value: accountTypes && accountTypes
        },
        {
          key: 'associatedEntity',
          name: 'Associated Entity',
          value: associatedEntity
        },
        {
          key: 'kycStatus',
          name: 'KYC Status',
          value: getApproveButton()
        },
        {
          key: 'userDiscounts',
          name: 'Discount Rate',
          value: <div>{discountRates}</div>
        },
        {
          key: 'referredBy',
          name: 'Referred By',
          value: referredBy
        },
        {
          key: 'referralEarned',
          name: 'Total Referral $ earned',
          value: referralEarned
        },
        {
          key: 'referralsMade',
          name: 'Total Referrals made',
          value: referralsMade
        }
      ];
      setUserDetailsRow(rowData);
    } else {
      setUserDetailsRow(defaultRowValues);
    }
  }, [userId]);

  return (
    <DetailsCard
      title="User Details"
      isLoading={false}
      contents={userDetailsRow}
    />
  );
};

export default UserDetails;
