import classNames from 'classnames';
import React from 'react';
import loader from '../../../public/images/icons/loader.svg';

interface LoaderProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  width?: number;
  height?: number;
  src?: string;
}

const Loader: React.FC<LoaderProps> = ({
  width = 24,
  height = 24,
  src = loader,
  ...props
}) => {
  return (
    <img
      className={classNames(props.className)}
      src={src}
      width={width}
      height={height}
      alt="Loading"
    />
  );
};

export default Loader;
