import AnchorLink from 'components/AnchorLink';
import numeral from 'numeral';
import React, { useContext, useState, useEffect } from 'react';
import Button from '../../../components/Button';
import DetailsCard from '../../../components/DetailsCard';
import { UserDetailsContext } from '../../../contexts/userContext';
import useGetLocation from '../../../services/api/user/getLocation';
import useGetPlatformUsage from '../../../services/api/user/getPlatformUsage';
import { CardRowValueProps } from '../helpers';
import { defaultRowValues, IPlatformUsage } from './helpers';

const PlatformUsage: React.FC = () => {
  const {
    userDetails: { userId }
  } = useContext(UserDetailsContext);

  const [platformUsage, setPlatformUsage] = useState<CardRowValueProps[]>([]);
  const [ip, setIp] = useState<string>('');

  const { isLoading: isLocationFetching, refetch: fetchLocation } =
    useGetLocation(
      { ip },
      {
        onSuccess: (location: string) => {
          setPlatformUsage(
            platformUsage.map((row) => {
              const newRow = row;
              if (newRow.key === 'lastLoginLocation') {
                newRow.value = location;
              }
              return newRow;
            })
          );
        }
      }
    );

  const { isLoading } = useGetPlatformUsage(
    { userId },
    {
      onSuccess: ({
        signUpTime,
        lastLogin,
        transactionSummary: {
          totalVolume,
          totalNumberOfTransactions,
          lastTransactionType
        },
        lastLoginIp,
        onfidoKycUrl,
        zendeskLink
      }: IPlatformUsage) => {
        const rowData = [
          { key: 'signupTime', name: 'Signup Time', value: signUpTime },
          { key: 'lastLogin', name: 'Last Login', value: lastLogin },
          {
            key: 'totalVolume',
            name: 'Total Volume',
            value: `A ${numeral(totalVolume).format('$0,0.00')}`
          },
          {
            key: 'totalNumberOfTransactions',
            name: 'No. of Transactions',
            value: totalNumberOfTransactions
          },
          {
            key: 'lastTransactionType',
            name: 'Last Transaction Type',
            value: lastTransactionType
          },
          { key: 'lastLoginIp', name: 'Last Login IP', value: lastLoginIp },
          {
            key: 'lastLoginLocation',
            name: 'Last Login Location',
            value: (
              <Button
                type="button"
                primary
                size="small"
                loading={isLocationFetching}
                disabled={!lastLoginIp}
                onClick={() => {
                  fetchLocation();
                }}
              >
                Fetch
              </Button>
            )
          },
          { key: 'onfidoKycUrl', name: 'Onfido KYC Link', value: onfidoKycUrl },
          {
            key: 'zendeskLink',
            name: 'Zendesk Link',
            value: <AnchorLink href={zendeskLink}>{zendeskLink}</AnchorLink>
          }
        ];
        setPlatformUsage(rowData);
        setIp(lastLoginIp);
      }
    }
  );

  useEffect(() => {
    if (!userId) {
      setPlatformUsage(defaultRowValues);
    }
  }, [userId]);

  return (
    <DetailsCard
      title="Platform Usage"
      isLoading={isLoading}
      contents={platformUsage}
    />
  );
};

export default PlatformUsage;
