import axios from 'axios';
import { useQuery } from 'react-query';

const loadMonoovaTx = async ({ txId }: { txId: string }) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/office/monoova/${txId}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }
  );
  return data;
};

const useLoadMonoovaTx = ({ txId }: { txId: string }, props = {}) =>
  useQuery(['loadMonoovaTx', { txId }], () => loadMonoovaTx({ txId }), {
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
    ...props
  });

export default useLoadMonoovaTx;
