import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import React, { useEffect } from 'react';

import {
  Column,
  usePagination,
  useTable,
  useSortBy,
  HeaderGroup,
  useFilters,
  useExpanded
} from 'react-table';
import { IKeyValueMap, TablePaginateProps } from '../../interfaces/shared';
import DefaultColumnFilter from './filters/DefaultColumnFilter';
import TablePagination from './pagination';

interface TableProps<T extends IKeyValueMap> {
  data: T[];
  columns: Column<T>[];
  name: string;
  paginateOptions: TablePaginateProps;
  totalPages: number;
  refetchData: (pageIndex: number, pageSize: number) => void;
}

function Table<T extends IKeyValueMap>({
  name,
  data,
  columns,
  paginateOptions: { page: pageNumber, size },
  totalPages,
  refetchData
}: TableProps<T>) {
  const generateSortingIndicator = (column: HeaderGroup<T>) => {
    if (column.isSorted) {
      if (column.isSortedDesc) {
        return (
          <ChevronDownIcon
            width="20px"
            className="inline align-top text-rp-black"
          />
        );
      }
      return (
        <ChevronUpIcon
          width="20px"
          className="inline align-top text-rp-black"
        />
      );
    }
    return '';
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: pageNumber,
        pageSize: size
      },
      manualPagination: true,
      pageCount: totalPages,
      defaultColumn: { Filter: DefaultColumnFilter }
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    refetchData(pageIndex, pageSize);
  }, [pageIndex, pageSize]);

  return (
    <div className="flex flex-col bg-white text-rp-black p-4 space-y-2 text-sm">
      <h2 className="pb-2 text-base">{name}</h2>
      <div className="overflow-x-auto">
        <div className="py-1 align-middle inline-block min-w-full">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded">
            <table
              className="min-w-full divide-y divide-gray-200"
              {...getTableProps()}
            >
              <thead className="bg-rp-grey">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        scope="col"
                        {...column.getHeaderProps()}
                        className="px-6 py-3 text-left font-medium text-rp-neutral uppercase tracking-wider"
                      >
                        <div {...column.getSortByToggleProps()}>
                          {column.render('Header')}
                          {generateSortingIndicator(column)}
                        </div>
                        <div>{column.canFilter && column.render('Filter')}</div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={classNames({
                        'bg-white': index % 2 === 0,
                        'bg-rp-grey': index % 2 !== 0
                      })}
                    >
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          className="px-6 py-4 whitespace-nowrap"
                        >
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <TablePagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
      />
    </div>
  );
}

export default Table;
