import { notify } from '@relaypay/uikit';
import { AxiosError } from 'axios';
import Button from 'components/Button';
import DetailsCard from 'components/DetailsCard';
import { CardRowValueProps } from 'containers/dashboard/helpers';
import PaymentAction from 'enums/paymentAction';
import React from 'react';
import useManualPayment from 'services/api/user/manualPayment';

interface NameMismatchModalProps {
  data: CardRowValueProps[];
  id: string;
}

const notes: string[] = [
  'Please use common sense in the case of a typo',
  'If bank name is blank, request a bank statement to proceed',
  'Refund the money if it’s a completely different name'
];

const NameMismatchModal: React.FC<NameMismatchModalProps> = ({ data, id }) => {
  const { refetch: releasePayment } = useManualPayment(
    { txId: id, action: PaymentAction.RELEASE },
    {
      onSuccess: () => {
        notify({ message: 'Payment released!' });
      },
      onError: (err: AxiosError) => {
        notify({ type: 'error', message: err.response?.data.message });
      }
    }
  );

  const { refetch: denyPayment } = useManualPayment(
    { txId: id, action: PaymentAction.DENY },
    {
      onSuccess: () => {
        notify({ message: 'Payment denied!' });
      },
      onError: (err: AxiosError) => {
        notify({ type: 'error', message: err.response?.data.message });
      }
    }
  );

  return (
    <>
      <DetailsCard title="Name Mismatch" contents={data} />
      <div className="my-2 text-sm">
        <h2>Notes:</h2>
        <ul className="py-2">
          {notes.map((note) => (
            <li key={note}>{note}</li>
          ))}
        </ul>
      </div>
      <div className="flex space-x-6">
        <Button primary onClick={() => releasePayment()}>
          Approve
        </Button>
        <Button secondary onClick={() => denyPayment()}>
          Refuse
        </Button>
      </div>
    </>
  );
};

export default NameMismatchModal;
