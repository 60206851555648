import React from 'react';

interface AnchorLinkProps {
  href: string;
}

const AnchorLink: React.FC<AnchorLinkProps> = ({ href, children }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer noopener"
      className="hover:text-button-default-text-hover"
    >
      {children}
    </a>
  );
};

export default AnchorLink;
