import React from 'react';
import { HeaderGroup } from 'react-table';
import { IKeyValueMap } from '../../../../interfaces/shared';

interface FilterProps<T extends IKeyValueMap> {
  column: HeaderGroup<T>;
}

function SelectColumnFilter<T extends IKeyValueMap>({
  column: { id, filterValue, preFilteredRows, setFilter }
}: FilterProps<T>) {
  const options = React.useMemo(() => {
    const data = new Set<string | number>();
    preFilteredRows.forEach((row) => {
      data.add(row.values[id]);
    });
    return [...data];
  }, [id, preFilteredRows]);

  return (
    <select
      value={filterValue}
      className="border"
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option) => (
        <option key={option || null} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

export default SelectColumnFilter;
